<template>
  <prosk-icon :icon="icon" />
</template>

<script>
  import ProskIcon from './ProskIcon.vue';

  export default {
    props: {
      fileName: {
        type: String,
        default: ''
      }
    },
    components: {
      ProskIcon
    },
    computed: {
      icon () {
        const extension = this.fileName.split('.').pop();
        switch (extension) {
          case 'doc':
          case 'docx':
            return 'doc-file';
          case 'pdf':
            return 'pdf-file';
          case 'xls':
          case 'xlsx':
            return 'xls-file';
          default:
            return 'doc-file';
        }
      }
    }
  }
</script>
